html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: normal;
  src: url("montserrat-v14-latin_cyrillic-regular.aadd922d.woff2") format("woff2"), url("montserrat-v14-latin_cyrillic-regular.f301bfff.woff") format("woff");
}

@font-face {
  font-family: MontserratSemiBold;
  font-style: normal;
  font-weight: normal;
  src: url("montserrat-v14-latin_cyrillic-600.04ceccd4.woff2") format("woff2"), url("montserrat-v14-latin_cyrillic-600.64060fb7.woff") format("woff");
}

@font-face {
  font-family: MontserratBold;
  font-style: normal;
  font-weight: normal;
  src: url("montserrat-v14-latin_cyrillic-700.dc9e795c.woff2") format("woff2"), url("montserrat-v14-latin_cyrillic-700.c2be36e3.woff") format("woff");
}

@font-face {
  font-family: MontserratLight;
  font-style: normal;
  font-weight: normal;
  src: url("montserrat-v14-latin_cyrillic-300.e0a3a925.woff2") format("woff2"), url("montserrat-v14-latin_cyrillic-300.82069fb7.woff") format("woff");
}

@font-face {
  font-family: MontserratMedium;
  font-style: normal;
  font-weight: normal;
  src: url("montserrat-v14-latin_cyrillic-500.d8c8434c.woff2") format("woff2"), url("montserrat-v14-latin_cyrillic-500.ed285b2c.woff") format("woff");
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.visually-hidden:not(:focus):not(:active), input[type="“checkbox”"].visually-hidden, input[type="“radio”"].visually-hidden {
  width: 1px;
  height: 1px;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0, 0, 0, 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

.container {
  max-width: 280px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .container {
    max-width: 688px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }
}

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.header {
  background-color: #112437;
  padding: 21px 0;
}

.header__logo {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: url("new-logo.78407c80.svg") 0 0 / contain no-repeat;
  margin: 0 auto;
  display: block;
}

.header__chat-link {
  color: #fff;
  cursor: pointer;
  z-index: 1;
  font-family: MontserratMedium, sans-serif;
  font-size: 12px;
  line-height: 26px;
  transition: color .5s;
  position: absolute;
  transform: translate(500px);
}

.header__chat-link:hover {
  color: #ff6b08;
}

.mobile-menu-closed .header__chat-link {
  color: #818181;
  transform: translate(34vw, 90vh);
}

.mobile-menu-closed {
  width: 93.75vw;
  height: 100vh;
  z-index: 3;
  background-color: #031c33;
  transition: all .5s;
  display: flex;
  position: absolute;
  top: 0;
  left: -93.75vw;
}

.mobile-menu-opened {
  z-index: 3;
  transition: all .5s;
  transform: translateX(93.75vw);
  box-shadow: 15px 0 25px rgba(29, 29, 27, .15);
}

.header-auth-mobile {
  height: 30px;
  align-items: center;
  display: flex;
  position: relative;
  top: 14.4vh;
  left: 46.875vw;
  transform: translateX(-50%);
}

.mobile-filters-wrapper {
  cursor: pointer;
  height: 24px;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-right: 26px;
  display: flex;
}

.mobile-clear-filters-wrapper {
  cursor: pointer;
  height: 24px;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.header__filters--btn {
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: url("tune-24px.647ebed4.svg");
  border: none;
  outline: none;
  margin-right: 5px;
  transition: all .5s;
}

.header__filters {
  color: #818181;
  font-family: MontserratMedium, sans-serif;
  font-size: 10px;
  line-height: 26px;
  transition: all .5s;
}

.header__clear-filter--btn {
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: url("replay-24px.4bcbd343.svg");
  border: none;
  outline: none;
  margin-right: 4px;
  transition: all .5s;
}

.header__clear-filter {
  color: #818181;
  font-family: MontserratMedium, sans-serif;
  font-size: 10px;
  line-height: 24px;
  transition: all .5s;
}

.mobile-buttons-wrapper {
  height: 24px;
  box-sizing: border-box;
  display: flex;
  position: absolute;
  top: 27.8vh;
  left: 6.7vw;
}

.mobile-buttons-wrapper .header__clear-filter, .mobile-buttons-wrapper .header__filters {
  line-height: 24px;
}

.categories-filter-mobile {
  width: 280px;
  height: 38.7vh;
  flex-wrap: wrap;
  display: flex;
  position: absolute;
  top: 36vh;
  left: 6.7vw;
}

.categories-filter__item:not(:last-of-type) {
  margin-right: 8px;
}

.categories-filter__item--btn {
  color: #818181;
  opacity: .5;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ced2d1;
  border-radius: 50px;
  outline: none;
  padding: 8px 10px;
  font-family: MontserratLight, sans-serif;
  font-size: 19px;
  transition: all .5s;
}

.active-category {
  color: #ff6b08;
  opacity: 1;
  border-color: #ff6b08;
}

.close-menu {
  width: 24px;
  height: 24px;
  background: url("close-24px.0e7c4041.svg");
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-24px, 24px);
}

.mobile-input-closed {
  color: #000;
  width: 300px;
  height: 40px;
  opacity: 0;
  outline: none;
  margin-right: 81px;
  padding-left: 30px;
  font-family: MontserratLight, sans-serif;
  font-size: 12px;
  transition: all .5s;
  display: none;
  position: absolute;
}

.mobile-input {
  display: unset;
  opacity: 1;
  transform: translateY(11px);
}

.mobile-input-btn {
  cursor: pointer;
  z-index: 1;
  width: 24px;
  height: 24px;
  background: url("search-24px-black.552d3c1d.svg");
  border: none;
  outline: none;
  display: none;
  position: absolute;
  transform: translate(128px, 11px);
}

.mobile-input-cross {
  z-index: 1;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: url("close-24px.0e7c4041.svg");
  border: none;
  outline: none;
  display: none;
  position: absolute;
  transform: translate(-133px, 11px);
}

.mobile-clear-filters-wrapper:hover .header__clear-filter {
  color: #ff6b08;
}

.mobile-clear-filters-wrapper:hover .header__clear-filter--btn {
  background: url("restore-24px-orange.5a9177de.svg");
}

.mobile-filters-wrapper:hover .header__filters {
  color: #ff6b08;
}

.mobile-filters-wrapper:hover .header__filters--btn {
  background: url("tune-24px-orange.71499e87.svg");
}

.account-menu__wrapper {
  width: 100%;
  height: 80px;
  background-color: #112437;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 15px 0 25px rgba(29, 29, 27, .15);
}

.account-btn {
  width: 180px;
  height: max-content;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  position: relative;
}

.account-title {
  color: #fff;
  height: max-content;
  font-family: MontserratRegular, sans-serif;
  font-size: 12px;
  transition: all .4s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-30%, -50%);
}

.account-avatar {
  width: 44px;
  height: 44px;
  margin-right: 20px;
}

.avatar {
  width: 100%;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all .4s;
}

.account-btn:hover {
  cursor: pointer;
}

.account-btn:hover .account-title, .account-btn:hover .avatar--hover {
  color: #ff6b08;
  transition: all .4s;
}

.account-btn:hover .avatar--hover {
  border: 2px solid #ff6b08;
}

@media (max-width: 767px) {
  .header {
    min-height: 92px;
  }

  .account-menu__wrapper {
    background-color: #031c33;
    margin-bottom: 30px;
  }

  .tablet-upper-wrapper, .tablet-bottom-wrapper {
    display: none;
  }

  .mobile-burger {
    width: 24px;
    height: 24px;
    background: url("dehaze-24px.3e4b6c90.svg");
    border: none;
    outline: none;
  }

  .mobile-search {
    width: 24px;
    height: 24px;
    background: url("search-24px.1c417611.svg");
    border: none;
    outline: none;
  }

  .mobile-menu-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 768px) {
  .header {
    padding: 34px 0;
  }

  .mobile-menu-wrapper {
    display: none;
  }

  .tablet-upper-wrapper {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    display: flex;
  }

  .header__logo {
    margin: 0 266px 0 0;
  }

  .header-auth {
    width: 140px;
    height: 26px;
    justify-content: space-around;
    align-items: center;
    margin-right: 30px;
    display: flex;
  }

  .header__create-ad-btn {
    letter-spacing: .28px;
    cursor: pointer;
    width: 200px;
    height: 44px;
    color: #fff;
    background-color: #ff6b08;
    border: none;
    outline: none;
    font-family: MontserratBold, sans-serif;
    font-size: 14px;
    transition: box-shadow .5s linear;
    display: block;
  }

  .header__create-ad-btn:hover {
    box-shadow: 0 2px 20px 10px rgba(255, 107, 8, .4);
  }

  .tablet-bottom-wrapper {
    align-items: center;
    display: flex;
    position: relative;
  }

  .header__input {
    color: #000;
    width: 405px;
    height: 44px;
    outline: none;
    margin-right: 81px;
    padding-left: 10px;
    font-family: MontserratLight, sans-serif;
    font-size: 12px;
  }

  .header__input--btn {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: url("search-24px-black.552d3c1d.svg");
    border: none;
    outline: none;
    position: absolute;
    transform: translate(375px);
  }

  .clear-filters-wrapper {
    cursor: pointer;
    flex-wrap: nowrap;
    justify-content: space-around;
    display: flex;
  }

  .filters-wrapper {
    cursor: pointer;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin-right: 48px;
    display: flex;
  }

  .clear-filters-wrapper:hover .header__clear-filter {
    color: #ff6b08;
  }

  .clear-filters-wrapper:hover .header__clear-filter--btn {
    background: url("restore-24px-orange.5a9177de.svg");
  }

  .filters-wrapper:hover .header__filters {
    color: #ff6b08;
  }

  .filters-wrapper:hover .header__filters--btn {
    background: url("tune-24px-orange.71499e87.svg");
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  .header__chat-link {
    transform: translate(75px);
  }

  .categories-filter-tablet {
    height: 100px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 15px;
    display: none;
  }

  .desktop-upper-wrapper .header__input, .tablet-bottom-wrapper .categories-filter, .tablet-upper-wrapper .header__input--btn {
    display: none;
  }
}

@media (min-width: 1280px) {
  .tablet-bottom-wrapper .header__input, .categories-filter-tablet {
    display: none;
  }

  .desktop-upper-wrapper .header__input {
    width: 405px;
    height: 44px;
    outline: none;
    margin-right: 286px;
  }

  .tablet-bottom-wrapper .header__input--btn, .tablet-bottom-wrapper .filters-wrapper {
    display: none;
  }

  .clear-filters-wrapper {
    margin: 0;
    position: absolute;
    right: 0;
  }

  .header__logo {
    margin-right: 35px;
  }

  .header__create-ad-btn {
    width: 233px;
  }

  .header-auth {
    margin-right: 55px;
  }

  .desktop-upper-wrapper .header__logo {
    margin-right: 36px;
  }

  .desktop-upper-wrapper {
    margin-bottom: 24px;
  }

  .header__clear-filter {
    font-size: 12px;
  }

  .categories-filter {
    display: flex;
  }

  .categories-filter__item:not(:last-of-type) {
    cursor: pointer;
    margin-right: 12px;
  }

  .categories-filter__item:hover .categories-filter__item--btn {
    cursor: pointer;
    color: #ff6b08;
    opacity: 1;
    border-color: #ff6b08;
  }

  .header__input--btn {
    transform: translate(456px);
  }
}

.header-auth-mobile .sign-up__btn, .header-auth-mobile .sign-in__btn {
  color: #fff;
}

.sign-up__btn {
  color: #818181;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  font-family: MontserratMedium, sans-serif;
  font-size: 12px;
  line-height: 26px;
  transition: all .5s;
  display: inline-block;
}

.sign-in__btn {
  color: #818181;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  font-family: MontserratMedium, sans-serif;
  font-size: 12px;
  line-height: 26px;
  transition: all .5s;
}

.sign-up__btn:hover, .sign-in__btn:hover {
  cursor: pointer;
  color: #ff6b08;
}

.mobile-menu-closed .sign-in-up__stick {
  content: "";
  width: 1px;
  height: 20px;
  background: url("stick.c54a8ab0.png");
  margin: 0 10px;
  display: inline-block;
}

@media (min-width: 768px) {
  .sign-in-up__stick {
    content: "";
    width: 1px;
    height: 20px;
    background: url("stick.c54a8ab0.png");
    display: inline-block;
    transform: translate(0);
  }
}

@media (min-width: 1280px) {
  .sign-in-up__stick {
    margin: 0 6px;
  }
}

.auth-modal {
  width: 280px;
  background: #fff;
  padding: 25px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 8px 24px rgba(29, 29, 27, .1);
}

.auth-modal__close-btn {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("close-24px.0e7c4041.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  position: absolute;
  top: 22px;
  right: 16px;
}

.auth-modal__form--title {
  color: #333;
  margin-bottom: 25px;
  margin-left: 18px;
  font-family: MontserratBold;
  font-size: 18px;
  line-height: 1.2;
}

.auth-modal__form--title:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.auth-modal__form--title:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.auth-modal__form--title:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.auth-modal__form {
  flex-flow: column;
  align-items: center;
  display: flex;
}

.auth-modal__input, .auth-modal__input-repeat {
  width: 246px;
  height: 48px;
  background-color: #fff;
  border: 1px solid #ced2d1;
  outline: none;
  margin-bottom: 10px;
  padding: 0 10px;
  font-family: MontserratMedium;
  font-size: 14px;
  line-height: 1.2;
}

.auth-modal__input::-ms-input-placeholder {
  color: #bbb;
  font-family: MontserratMedium;
  font-size: 14px;
  line-height: 1.2;
}

.auth-modal__input::placeholder {
  color: #bbb;
  font-family: MontserratMedium;
  font-size: 14px;
  line-height: 1.2;
}

.auth-modal__submit-btn {
  width: 248px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #ff6b08;
  border: none;
  outline: none;
  margin-bottom: 6px;
  font-family: MontserratMedium;
  font-size: 16px;
  line-height: 50px;
}

.auth-modal__submit-btn:hover, .auth-modal__submit-btn:focus {
  background-color: #646464;
}

.auth-modal__and-text {
  color: #bbb;
  margin-bottom: 6px;
  font-size: 12px;
}

.auth-modal__submit-btn-reg {
  width: 160px;
  color: #333;
  text-align: center;
  cursor: pointer;
  background-color: #ced2d1;
  border: none;
  outline: none;
  font-family: MontserratMedium;
  font-size: 12px;
  line-height: 25px;
}

.auth-modal__submit-btn-reg:hover, .auth-modal__submit-btn-reg:focus {
  color: #fff;
  background-color: #646464;
}

@media (min-width: 768px) {
  .auth-modal {
    width: 600px;
    padding-top: 48px 0;
    box-shadow: 0 12px 40px rgba(0, 0, 0, .25);
  }

  .auth-modal__form--title {
    margin-bottom: 36px;
    margin-left: 54px;
    font-size: 22px;
    line-height: 1.2;
  }

  .auth-modal__input, .auth-modal__input-repeat {
    width: 400px;
    height: 57px;
    padding: 0 10px;
    font-size: 14px;
  }

  .auth-modal__submit-btn {
    width: 294px;
    height: 60px;
    margin-right: 12px;
    font-size: 14px;
  }

  .auth-modal__submit-btn-reg {
    width: 160px;
    color: #333;
    text-align: center;
    cursor: pointer;
    background-color: #ced2d1;
    border: none;
    outline: none;
    font-family: MontserratMedium;
    font-size: 12px;
    line-height: 25px;
  }
}

@media (min-width: 1280px) {
  .auth-modal {
    padding: 54px 0;
  }

  .auth-modal__input, .auth-modal__input-repeat {
    height: 56px;
    padding: 0 10px;
  }

  .auth-modal__submit-btn {
    width: 294px;
    height: 60px;
  }
}

.auth-modal-err {
  width: 280px;
  background: #fff;
  padding: 25px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 8px 24px rgba(29, 29, 27, .1);
}

.auth-modal-err__close-btn {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("close-24px.0e7c4041.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  position: absolute;
  top: 22px;
  right: 16px;
}

.auth-modal-err__form--title {
  color: #333;
  margin-bottom: 20px;
  margin-left: 18px;
  font-family: MontserratBold;
  font-size: 18px;
  line-height: 1.2;
}

.auth-modal-err__form--title:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.auth-modal-err__form--title:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.auth-modal-err__form--title:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.auth-modal-err__text {
  width: 246px;
  text-align: center;
  text-transform: uppercase;
  color: #112437;
  margin: 0 auto 20px;
  padding: 15px 0 0;
  font-size: 18px;
}

@media (min-width: 768px) {
  .auth-modal-err {
    width: 400px;
    padding-top: 48px 0;
    box-shadow: 0 12px 40px rgba(0, 0, 0, .25);
  }

  .auth-modal-err__form--title {
    margin-bottom: 20px;
    margin-left: 54px;
    font-size: 22px;
    line-height: 1.2;
  }
}

@media (min-width: 1280px) {
  .auth-modal-err {
    padding: 54px 0;
  }
}

.loader-wrapper {
  width: 100%;
  height: 100%px;
  background-color: #242f3f;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0;
  left: 0;
}

.loader-wrapper-hide {
  display: none;
}

.loader {
  width: 30px;
  height: 30px;
  border: 4px solid #fff;
  animation: loader 2s infinite;
  display: inline-block;
  position: relative;
}

.loader-inner {
  vertical-align: top;
  width: 100%;
  background-color: #fff;
  animation: loader-inner 2s ease-in infinite;
  display: inline-block;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.loader-cover {
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  top: 0;
}

.loader-cover-none {
  display: none;
}

.wrapper-item-modal {
  width: 320px;
  background-color: #fff;
  margin: 0 auto;
  padding: 12px 0;
  position: relative;
  top: 4%;
}

.icon-cross {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("baseline_chevron_left_black_24dp.90d9b2f8.png");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  position: absolute;
  top: 8px;
  left: 4px;
}

.item_modal--tablet_wrapper {
  width: 280px;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
}

.item_modal--header {
  color: #000;
  letter-spacing: .32px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: MontserratBold\, sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
}

.item_modal--item-codename {
  color: #989797;
  letter-spacing: .32px;
  margin-bottom: 16px;
  font-family: MontserratRegular\, sans-serif;
  font-size: 12px;
  line-height: 1.2;
  display: block;
}

.item_modal--item-price {
  letter-spacing: .32px;
  color: #ff6b08;
  margin-bottom: 16px;
  font-family: MontserratBold\, sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  display: block;
}

.item_modal--tablet--button-buy {
  letter-spacing: .28px;
  cursor: pointer;
  width: 280px;
  height: 60px;
  color: #fff;
  background-color: #ff6b08;
  border: 1px solid #ff6b08;
  outline: none;
  margin-bottom: 16px;
  font-family: MontserratBold, sans-serif;
  font-size: 16px;
  transition: box-shadow .3s linear;
  display: block;
}

.item_modal--tablet--button-buy-active {
  letter-spacing: .28px;
  cursor: pointer;
  width: 280px;
  height: 60px;
  color: #f95;
  background-color: #fff;
  border: 1px solid #f95;
  outline: none;
  margin-bottom: 16px;
  font-family: MontserratBold, sans-serif;
  font-size: 16px;
  transition: box-shadow .3s linear;
  display: block;
}

.item_modal--tablet--button-buy:hover, .item_modal--tablet--button-buy-active:hover {
  box-shadow: 0 0 10px rgba(8, 45, 126, .24), 0 10px 30px rgba(163, 17, 17, .19);
}

.item_modal--button-fav {
  color: #878686;
  vertical-align: middle;
  font-family: MontserratRegular\, sans-serif;
  font-size: 12px;
  line-height: 1.2;
}

.item_modal--desc-header {
  color: #878686;
  margin-bottom: 14px;
  font-family: MontserratRegular\, sans-serif;
  font-size: 12px;
  line-height: 1.2;
}

.icon-fav {
  color: #468464;
  width: 16px;
  height: 16px;
  cursor: pointer;
  vertical-align: middle;
  background: url("heart_grey.abebb3fa.svg") 0 0 / contain;
  border: none;
  outline: none;
  margin-right: 10px;
  display: inline-block;
}

.icon-fav--active {
  color: #ee2b2b;
  width: 16px;
  height: 16px;
  stroke-width: 0;
  vertical-align: middle;
  background: url("heart_red.ea0dd259.svg") 0 0 / contain;
  border: none;
  margin-right: 10px;
  display: inline-block;
}

.item_modal--slider_s-img_big {
  width: 280px;
  background-color: #fff;
}

.item_modal--icons {
  align-content: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  display: flex;
}

.item_modal--icons-disactive {
  display: none;
}

.item_modal--imageholder {
  width: 100%;
  max-width: 30rem;
  height: 300px;
  margin: 0 auto;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
}

.item_modal--slider-s {
  margin-bottom: 36px;
}

.siema {
  margin: 1rem 0;
}

.dots {
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
}

.dots__item {
  width: 7px;
  height: 7px;
  cursor: pointer;
  background-color: #ccc;
  border: 0;
  border-radius: 50%;
  outline: 0;
  margin: 0 .5rem;
}

.dots__item--active {
  background: #ff6b08;
}

.item_modal-seller {
  display: none;
}

.item_modal-seller-active {
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.avatarAuth {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 2px solid #ff6b08;
  border-radius: 50%;
  margin-right: 20px;
}

.item_modal-seller--header {
  color: #878686;
  letter-spacing: .32px;
  margin-bottom: 10px;
  font-family: MontserratRegular\, sans-serif;
  font-size: 12px;
  line-height: 1.2;
}

.item_modal-seller--name {
  color: #878686;
  letter-spacing: .32px;
  font-family: MontserratRegular\, sans-serif;
  font-size: 14px;
  line-height: 1.2;
}

.item_modal-seller--tel {
  color: #ff6b08;
  letter-spacing: .32px;
  font-family: MontserratRegular\, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
}

.item_modal--tablet_slider {
  min-width: 340px;
  flex-direction: column;
  align-content: flex-start;
  display: none;
}

.item_modal--tablet-second_wrapper {
  width: 280px;
}

.item_modal--desctab {
  color: #000;
  width: 260px;
  font-family: MontserratRegular\, sans-serif;
  font-size: 13px;
  line-height: 1.385;
}

.images-mini {
  display: flex;
}

.slider_image-max {
  width: 334px;
  height: 372px;
  margin-bottom: 16x;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
}

@media (min-width: 768px) {
  .container {
    max-width: 688px;
  }

  .wrapper-item-modal {
    width: 724px;
  }

  .icon-arrow, .item_modal--imageholder, .item_modal--slider-s, .dots, .dots__item, .dots__item--active {
    display: none;
  }

  .item_modal--tablet {
    width: 724px;
    margin: 0 auto;
    padding: 26px;
    position: relative;
  }

  .item_modal--header {
    margin-top: 20px;
  }

  .wrapper-item-modal {
    top: 50%;
    transform: translateY(-50%);
  }

  .item_modal--tablet_wrapper {
    width: 674px;
    justify-content: space-between;
    display: flex;
  }

  .item_modal--tablet_slider {
    min-width: 340px;
    flex-direction: column;
    align-content: flex-start;
    display: flex;
  }

  .item_modal--tablet-second_wrapper {
    min-width: 316px;
  }

  .icon-cross {
    top: 20px;
    left: unset;
    background-image: url("close-24px.0e7c4041.svg");
    right: 16px;
  }

  .item_modal--tablet--button-buy, .item_modal--tablet--button-buy-active {
    width: 294px;
    height: 60px;
  }
}

.item_modal--desctab {
  line-height: 1.385;
}

.images-mini {
  display: flex;
}

.slider_image-max {
  width: 334px;
  height: 372px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
}

.bg-photo--style {
  width: 334px;
  height: 372px;
  object-fit: contain;
}

.slider_image-min {
  width: 52px;
  height: 54px;
  cursor: pointer;
  object-fit: contain;
  background-color: #f4f6fb;
  border: 3px solid #f3f3f3;
  justify-content: center;
  align-items: center;
  display: flex;
}

.slider_image-min--active {
  width: 52px;
  height: 54px;
  cursor: pointer;
  object-fit: contain;
  background-color: #f4f6fb;
  border: 3px solid #fdbc91;
  justify-content: center;
  align-items: center;
  transition: all .2s linear;
  display: flex;
}

.sm-photo--style {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.slider_image-min:not(:last-child) {
  margin-right: 4px;
}

@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }

  .wrapper-item-modal {
    width: 1028px;
  }

  .item_modal--tablet {
    width: 1028px;
    margin: 0 auto;
    padding: 26px;
  }

  .slider_image-min:not(:last-child) {
    margin-right: 8px;
  }

  .item_modal--tablet_wrapper {
    width: 874px;
    justify-content: space-between;
    display: flex;
  }

  .item_modal--tablet_slider {
    min-width: 408px;
    flex-direction: column;
    align-content: flex-start;
    display: flex;
  }

  .item_modal--tablet-second_wrapper {
    min-width: 410px;
  }

  .slider_image-max {
    width: 403px;
    height: 448px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    display: flex;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
  }

  .slider_image-min, .slider_image-min--active {
    width: 60px;
    height: 62px;
  }

  .item_modal--desctab {
    width: 410px;
    line-height: 1.385;
  }
}

.phone-item-icon {
  color: #000;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-image: url("s_phone.f4df165c.svg");
  background-size: contain;
  border: none;
  margin-right: 10px;
  display: inline-block;
}

.categories {
  min-height: 380px;
  position: relative;
}

.category-list {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  padding-bottom: 54px;
}

.category-list:first-of-type {
  padding-top: 54px;
}

.category-info {
  margin-bottom: 20px;
}

.category-name {
  color: #000;
  text-align: left;
  margin-right: 12px;
  font-family: MontserratSemiBold;
  font-size: 20px;
  line-height: 1.2;
}

.view-all {
  cursor: pointer;
  color: #ff6b08;
  text-align: left;
  font-family: MontserratRegular;
  font-size: 12px;
  line-height: 1.2;
  text-decoration: underline;
}

.category-description {
  color: #a8a8a8;
  text-align: left;
  margin-bottom: 26px;
  font-family: MontserratRegular;
  font-size: 12px;
  line-height: 1.5;
}

.things-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  width: 100%;
  height: 305px;
  cursor: pointer;
  padding: 20px 0;
  transition: transform .5s, filter 1.5s ease-in-out;
}

.things-item:hover {
  transform: scale(1.1);
}

.thing-img {
  object-fit: cover;
  width: 240px;
  height: 240px;
  margin: 0 auto 5px;
}

.thing-name {
  color: #000;
  text-transform: uppercase;
  text-align: center;
  max-width: 240px;
  max-height: 14px;
  margin: 0 auto;
  font-family: MontserratSemiBold;
  font-size: 12px;
  line-height: 1.2;
  display: block;
  overflow: hidden;
}

.thing-price {
  color: #e96105;
  text-align: center;
  font-family: MontserratSemiBold;
  font-size: 12px;
  line-height: 1.2;
  display: block;
}

.hide {
  display: none;
}

.hide-two {
  opacity: 0;
}

.all-category {
  opacity: 0;
  min-height: 500px;
}

.all-category-show {
  opacity: 1;
  transition: all 1s linear;
}

.close-category {
  opacity: 0;
  width: 50px;
  height: 50px;
  z-index: 1;
  cursor: pointer;
  background-color: #ff6b08;
  background-image: url("baseline_close_black_24dp.27b9fd08.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
  border: none;
  border-radius: 50%;
  outline: none;
  position: absolute;
  top: 50px;
  right: 50px;
  box-shadow: 0 8px 8px rgba(233, 97, 5, .24);
}

.close-category-show {
  opacity: 1;
  transition: opacity 1s linear, background-color .5s;
}

.close-category:hover {
  background-color: #fff;
}

.things-item {
  min-width: 280px !important;
}

@media (max-width: 767px) {
  .category-wrapper, .electronics-wrapper, .property-wrapper, .transport-wrapper, .businessAndServices-wrapper, .recreationAndSport-wrapper, .free-wrapper, .exchange-wrapper {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    width: 100%;
  }

  .category-list {
    width: 100%;
  }

  .categories {
    position: relative;
  }

  .close-category {
    background-color: #fff;
    top: 30px;
    right: 22px;
  }

  .slide-prev, .slide-next {
    width: 10px;
    height: 20px;
    background-color: #f4f4f4;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    outline: none;
    margin-top: 5px;
    padding: 5px;
    box-shadow: 2.5px 4.33px 10px rgba(29, 29, 27, .11);
  }

  .slide-prev:hover, .slide-next:hover {
    background-color: #e96105;
  }

  .slide-prev {
    background-image: url("back.e848b8a7.svg");
    margin-right: 5px;
    left: 0;
  }

  .slide-next {
    background-image: url("next.593066df.svg");
    right: 0;
  }

  .modal-btn {
    width: 50px;
    height: 50px;
    background-color: #ff6b08;
    border: none;
    border-radius: 50%;
    outline: none;
    display: block;
    position: fixed;
    top: 80vh;
    right: 22px;
    box-shadow: 0 8px 8px rgba(233, 97, 5, .24);
  }

  .modal-btn:after {
    content: "+";
    color: #fff;
  }

  .things-list {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    overflow: hidden;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
  }
}

@media (min-width: 768px) {
  .category-list {
    position: relative;
  }

  main .modal-btn {
    width: 1px;
    height: 1px;
    white-space: nowrap;
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
    clip: rect(0, 0, 0, 0);
    border: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .slide-prev {
    width: 32px;
    height: 60px;
    background-color: #f4f4f4;
    background-image: url("back.e848b8a7.svg");
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    outline: none;
    transition: all .2s linear;
    position: absolute;
    top: 50%;
    box-shadow: 2.5px 4.33px 10px rgba(29, 29, 27, .11);
  }

  .slide-prev:hover {
    background-color: #e96105;
  }

  .slide-next {
    width: 32px;
    height: 60px;
    background-color: #f4f4f4;
    background-image: url("next.593066df.svg");
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    outline: none;
    transition: all .2s linear;
    position: absolute;
    top: 50%;
    right: 0;
    box-shadow: 2.5px 4.33px 10px rgba(29, 29, 27, .11);
  }

  .slide-next:hover {
    background-color: #e96105;
  }

  .things-list {
    padding: 2px 0;
  }

  .things-item {
    height: 305px;
    margin: 0 auto 20px;
    display: block;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
  }

  .all-category {
    z-index: 1;
    background-color: #fff;
  }

  .category-line {
    flex-wrap: wrap;
    display: flex;
  }
}

@media (min-width: 1200px) {
  .close-category {
    right: 120px;
  }

  .all-category {
    min-height: 700px;
  }
}

.not-found {
  color: #000;
  text-align: center;
  padding-top: 200px;
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-weight: 700;
}

.account-menu__wrapper {
  width: 100%;
  height: 114px;
  background-color: #131f30;
  justify-content: center;
  align-items: center;
  display: flex;
}

.avatar {
  height: 100%;
  object-fit: cover;
}

.avatare-large {
  width: 85px;
  height: 85px;
  box-shadow: 2px 2px 8px -1px rgba(155, 155, 153, .753);
}

.avatar-shadow {
  border: 1px solid #fff;
  box-shadow: 2px 1px 5px rgba(155, 155, 153, .753);
}

.account-logout {
  line-height: 20px;
}

.account-title {
  width: max-content;
}

.account-btn:hover {
  cursor: pointer;
}

.account-btn:hover .account-title, .account-btn:hover .avatar--hover {
  color: #ff6b08;
}

.account-btn:hover .avatar--hover {
  border: 2px solid #ff6b08;
}

.modal-backdrop {
  width: 100%;
  height: 100%;
  z-index: 10;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.moodal-backdrop__favorites, .moodal-backdrop__my-ads {
  background-color: rgba(0, 0, 0, .3);
  padding-top: 20px;
}

.account-wrapper {
  width: 280px;
  background-color: #fff;
  padding: 40px 0 20px;
  position: relative;
}

.modal__icon--close {
  content: "";
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-image: url("close.ac8c96bb.svg");
  background-position: center;
  background-repeat: no-repeat;
  transition: all .4s ease-in-out;
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal__icon--close:hover {
  background-image: url("close--hover.24a35961.svg");
}

.user-avatar__file-input {
  display: none;
}

.user-avatar {
  width: 85px;
  height: 85px;
  margin: 0 auto 15px;
  position: relative;
}

.user-avatar:hover .user-avatar__add-btn, .user-avatar:hover .user-avatar__clear-btn {
  cursor: pointer;
  display: block;
}

.user-avatar__add-btn, .user-avatar__clear-btn {
  content: "";
  width: 31px;
  height: 31px;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #fff;
  border-radius: 50%;
  transition: all .5s ease-in-out;
  display: none;
  position: absolute;
  top: 80%;
  box-shadow: 2px 2px 6px -1px rgba(155, 155, 153, .753);
}

.user-avatar__add-btn {
  right: -15%;
  transform: translate(50%, -50%);
}

.user-avatar__clear-btn {
  left: -15%;
  transform: translate(-50%, -50%);
}

.avatar__icon--camera__add {
  background-color: #10bf86;
  background-image: url("camera.4c796eb0.svg");
}

.avatar__icon--camera__clear {
  background-color: #fb4d19;
  background-image: url("camera-off.f45013e1.svg");
}

.avatar__icon--camera__add:hover, .avatar__icon--camera__clear:hover {
  cursor: pointer;
  background-color: #fff;
}

.avatar__icon--camera__add:hover {
  background-image: url("camera--hover.036fef4d.svg");
  border: 1px solid #10bf86;
}

.avatar__icon--camera__clear:hover {
  background-image: url("camera-off--hover.f544b4a0.svg");
  border: 1px solid #fb4d19;
}

.user-info {
  text-align: center;
  margin-bottom: 20px;
}

.user-info__fullname, .user-info__mail, .user-info__phone {
  color: #333;
  letter-spacing: .29px;
  line-height: 22px;
}

.user-info__fullname {
  margin-bottom: 10px;
  font-family: MontserratSemiBold, sans-serif;
  font-size: 16px;
}

.user-info__mail, .user-info__phone {
  font-family: MontserratRegular, sans-serif;
  font-size: 14px;
}

.hr--line:after, .hr--line:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgba(133, 133, 133, .38);
  margin-bottom: 20px;
  display: block;
}

.account-list {
  margin: 0 18px;
}

.account-list__favorites, .account-list__advertisement {
  margin: 0 0 25px 16px;
  padding-left: 40px;
  font-family: MontserratRegular, sans-serif;
  font-size: 16px;
  line-height: 24px;
  transition: all .4s ease-in-out;
}

.account-list__favorites:hover, .account-list__advertisement:hover, .account-logout:hover {
  color: #ff6b08;
  cursor: pointer;
}

.icon--heart, .icon--heart-on, .icon--add, .icon--pencil-on, .icon--logout {
  background-repeat: no-repeat;
}

.icon--heart {
  background-image: url("heart-out.39c5ea95.svg");
}

.icon--heart-on {
  background-image: url("heart-on--hover.74d6581d.svg");
}

.icon--add {
  background-image: url("pencil.27aa070e.svg");
}

.icon--logout {
  background-image: url("logout.e4c5ea4f.svg");
}

.icon--pencil-on {
  background-image: url("pencil--hover.25aff0f1.svg");
}

.icon--heart:hover {
  background-image: url("heart-out--hover.96353fe5.svg");
}

.icon--add:hover {
  background-image: url("pencil--hover.25aff0f1.svg");
}

.icon--logout:hover {
  background-image: url("logout--hover.84e47eed.svg");
}

.account-list__favorites-link, .account-list__advertisement-link {
  margin-right: 10px;
}

.counter {
  width: 20px;
  height: 20px;
  text-align: center;
  color: #333;
  border-radius: 50%;
  font-size: 12px;
  line-height: 20px;
  transition: all .4s ease-in-out;
  display: inline-block;
  box-shadow: 1px 1px 3px rgba(155, 155, 153, .753);
}

.icon--heart:hover .counter, .icon--add:hover .counter {
  color: #fff;
  background-color: #ff6b08;
}

.account-logout {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin-left: 35px;
  padding-left: 40px;
  font-family: MontserratSemiBold, sans-serif;
  font-size: 16px;
  transition: all .4s ease-in-out;
}

.selected-goods__inner, .my-ads__inner {
  width: 280px;
  height: 500px;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  overflow: auto;
}

.selected-goods, .my-calls {
  width: 240px;
  margin: 0 auto;
}

.selected-goods__title, .my-ads__title {
  color: #333;
  margin-bottom: 20px;
  padding-left: 40px;
  font-family: MontserratBold, sans-serif;
  font-size: 18px;
  line-height: 24px;
}

.selected-goods__list, .my-ads__list {
  width: 100%;
  height: 100%;
}

.selected-goods__item, .my-ads__item {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  transition: all .6s ease-in-out;
  position: relative;
  box-shadow: 0 0 6px -2px #cecece;
}

.selected-goods__item:hover, .my-ads__item:hover {
  cursor: pointer;
  transform: scale(1.03);
  box-shadow: 2px 2px 6px -1px #d1d1d1;
}

.selected-goods__item-img, .my-ads__item-img {
  width: 220px;
  height: 180px;
  object-fit: contain;
  display: block;
}

.selected-goods__item-title, .selected-goods__item-price, .my-ads__item-title, .my-ads__item-price {
  margin-bottom: 15px;
  font-family: MontserratBold, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.selected-goods__item-title, .my-ads__item-title {
  text-align: center;
  text-transform: uppercase;
  color: #000;
}

.selected-goods__item-price, .my-ads__item-price {
  color: #e96105;
}

.selected-goods__item-desc, .my-ads__item-desc {
  color: #878686;
  padding-bottom: 30px;
  font-family: MontserratLight, sans-serif;
  font-size: 13px;
  line-height: 18px;
  display: none;
}

.selected-goods__icon-text, .my-ads__icon-text {
  width: max-content;
  color: #878686;
  font-family: MontserratLight, sans-serif;
  font-size: 12px;
  line-height: 24px;
  display: none;
  position: absolute;
  bottom: 0;
  right: 30px;
}

.selected-goods__icon-text--hidden {
  display: none;
}

.selected-goods__item-icon, .my-ads__item-icon {
  content: "";
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .4s ease-in-out;
  position: absolute;
  bottom: 14px;
  right: 15px;
}

.selected-goods__item-icon {
  background-image: url("heart-on--hover.74d6581d.svg");
}

.my-ads__item-icon {
  background-image: url("delete.b9f6afab.svg");
}

.selected-goods__item-icon:hover {
  background-image: url("heart-out--hover.96353fe5.svg");
}

.my-ads__item-icon:hover {
  background-image: url("delete-forever.e6fb363e.svg");
}

.selected-goods__item-icon:hover .selected-goods__icon-text, .my-ads__item-icon:hover .my-ads__icon-text {
  display: inline-block;
}

@media (max-width: 767px) {
  .modal-backdrop {
    background-color: rgba(0, 0, 0, .3);
  }

  .account-wrapper {
    margin: 0 auto;
    top: 12%;
  }

  .selected-goods__inner, .my-ads__inner {
    padding: 40px 0 20px;
  }

  .selected-goods__item, .my-ads__item {
    padding: 15px;
  }

  .selected-goods__item-img, .my-ads__item-img {
    margin: 0 auto 20px;
  }
}

@media (min-width: 768px) {
  .account-menu__wrapper {
    height: 112px;
    justify-content: flex-end;
  }

  .account-btn {
    width: max-content;
    left: 50%;
  }

  .account-title {
    display: none;
  }

  .account-wrapper {
    top: 34px;
    left: 40%;
    box-shadow: 1px 1px 6px -2px silver;
  }

  .selected-goods__inner, .my-ads__inner {
    width: 720px;
    height: 680px;
    padding-top: 50px;
  }

  .modal__icon--close:before {
    top: 25px;
    right: 25px;
  }

  .selected-goods, .my-calls {
    width: 600px;
  }

  .selected-goods__item, .my-ads__item {
    padding: 15px 20px;
    display: flex;
  }

  .selected-goods__item-img, .my-ads__item-img {
    width: 170px;
    height: 146px;
    margin: 0;
  }

  .selected-goods__item-block__info, .my-ads__item-block__info {
    width: 100%;
    height: max-content;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 20px;
    display: flex;
  }

  .selected-goods__item-desc, .my-ads__item-desc {
    display: block;
  }

  .selected-goods__icon-text, .my-ads__icon-text {
    right: 35px;
  }

  .selected-goods__icon-text--hidden {
    display: inline-block;
  }

  .selected-goods__item-icon, .my-ads__item-icon {
    bottom: 12px;
    right: 18px;
  }
}

@media (min-width: 1024px) {
  .account-wrapper {
    left: 50%;
  }

  .selected-goods__inner, .my-ads__inner {
    width: 940px;
    height: 775px;
    padding-top: 60px;
  }

  .selected-goods, .my-calls {
    width: 810px;
  }

  .selected-goods__item, .my-ads__item {
    margin-bottom: 25px;
    padding: 20px 40px;
  }

  .selected-goods__item-img, .my-ads__item-img {
    width: 185px;
    height: 160px;
    margin: 0;
  }

  .selected-goods__item-block__info, .my-ads__item-block__info {
    padding-left: 40px;
  }

  .selected-goods__item-title, .selected-goods__item-price, .my-ads__item-title, .my-ads__item-price {
    font-size: 16px;
  }

  .selected-goods__item-desc, .my-ads__item-desc {
    font-size: 14px;
    display: block;
  }

  .selected-goods__icon-text, .my-ads__icon-text {
    font-size: 13px;
  }

  .selected-goods__icon-text--hidden {
    display: inline-block;
  }

  .selected-goods__item-icon, .my-ads__item-icon {
    bottom: 18px;
    right: 38px;
  }
}

.modalContainer {
  position: relative;
}

.modal {
  width: 100vw;
  height: 100vh;
  z-index: 3;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, .3);
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.transition-effect {
  transition: opacity .5s ease-in-out, visibility .5s ease-in-out;
}

.show-modal {
  pointer-events: initial;
  opacity: 1;
  visibility: visible;
}

​ .innerBlock2 {
  width: 300px;
  height: 500px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

​ .closeBtnAdv {
  position: absolute;
  top: 5px;
  right: 5px;
}

.hide-price, .hide {
  display: none;
}

.error {
  color: red;
  padding-bottom: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  position: absolute;
}

@media (min-width: 768px) and (max-width: 1279px) {
  .error {
    padding-left: 152px;
    position: static;
  }
}

@media (min-width: 1280px) {
  .error {
    padding-left: 230px;
    position: static;
  }
}

@media (max-width: 767px) {
  .error {
    text-align: center;
    transform: translate(-10px, -20px);
  }

  .main-adv-div {
    padding-bottom: 30px;
  }

  .description-error {
    padding-left: 16px;
  }

  .price-error {
    padding-left: 12px;
  }
}

.adv-modal {
  width: 280px;
  background: #fff;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  top: 10%;
  box-shadow: 0 8px 24px rgba(29, 29, 27, .1);
}

.adv-modal__close-btn {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("close-24px.0e7c4041.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  position: absolute;
  top: 22px;
  right: 16px;
}

.adv-modal__form--title {
  color: #333;
  margin-bottom: 25px;
  margin-left: 18px;
  font-family: MontserratBold;
  font-size: 18px;
  line-height: 1.2;
}

.adv-modal__form--title:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.adv-modal__form--title:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.adv-modal__form--title:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.adv-modal__form {
  flex-flow: column;
  align-items: center;
  display: flex;
}

.adv-modal__product-name-title, .adv-modal__product-description-title, .adv-modal__product-select-title, .adv-modal__product-price-title, .adv-modal__product-photos-title {
  display: none;
}

.input {
  width: 246px;
  height: 48px;
  background-color: #fff;
  border: 1px solid #ced2d1;
  margin-bottom: 20px;
  font-family: MontserratMedium;
  font-size: 14px;
  line-height: 1.2;
}

.input:required:invalid:not(:placeholder-shown) {
  background-color: #f7f3f2;
  border: 1px solid red;
}

.input:required:valid:not(:placeholder-shown) {
  background-color: #f2f7ee;
  border: 1px solid green;
}

.input:hover, .input:focus {
  border: 1px solid #ff6b08;
  outline: none;
}

.input::-ms-input-placeholder {
  color: #bbb;
  font-family: MontserratMedium;
  font-size: 14px;
  line-height: 1.2;
}

.input::placeholder {
  color: #bbb;
  font-family: MontserratMedium;
  font-size: 14px;
  line-height: 1.2;
}

.adv-modal__product-name, .adv-modal__product-description, .adv-modal__product-price {
  padding-left: 18px;
  font-family: MontserratMedium;
  font-size: 14px;
}

.adv-modal__product-photos {
  width: 246px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
}

.choose-this {
  background-image: url("+.8f99ef24.png");
  background-position: center;
  background-repeat: no-repeat;
}

.choose-this:hover, .choose-this:focus {
  cursor: pointer;
}

.photo-input {
  display: none;
}

.input-label {
  width: 75px;
  height: 59px;
  background-color: #f4f6fb;
  display: block;
}

.input-label__img {
  width: 76px;
  height: 60px;
  object-fit: cover;
}

.adv-modal__product-description {
  resize: vertical;
  min-height: 48px;
  max-height: 300px;
  padding-top: 16px;
  padding-right: 20px;
}

.adv-modal__product-select {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  color: #bbb;
  cursor: pointer;
  background-image: url("icon-drop-down.7b6b05f3.png");
  background-position: 90%;
  background-repeat: no-repeat;
  padding-left: 18px;
}

.adv-modal__product-price {
  margin-bottom: 30px;
}

.add-product-btn {
  width: 248px;
  height: 50px;
  color: #fff;
  text-align: center;
  background-color: #ff6b08;
  border: none;
  font-family: MontserratMedium;
  font-size: 16px;
  line-height: 50px;
  transition: all .5s;
}

.add-product-btn:hover, .add-product-btn:focus {
  cursor: pointer;
  outline: none;
  box-shadow: 0 1px 10px 5px rgba(255, 107, 8, .4);
}

@media (max-width: 767px) {
  .adv-modal__product-photos-item:not(:nth-last-child(-n+3)) {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .adv-modal {
    width: 720px;
    padding-top: 48px;
    padding-bottom: 50px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 12px 40px rgba(0, 0, 0, .25);
  }

  .adv-modal__form--title {
    margin-bottom: 36px;
    margin-left: 54px;
    font-size: 22px;
    line-height: 1.2;
  }

  .input {
    width: 458px;
    height: 57px;
    margin: 0;
    font-size: 14px;
  }

  .adv-modal__product-description {
    min-height: 57px;
  }

  .adv-modal__product-name-title, .adv-modal__product-description-title, .adv-modal__product-select-title, .adv-modal__product-price-title, .adv-modal__product-photos-title {
    color: #000;
    font-family: MontserratMedium;
    font-size: 14px;
    line-height: 1.2;
    display: block;
  }

  .adv-modal__product-photos {
    width: 458px;
    transition: all .5s;
  }

  .input-label, .input-label__img {
    width: 72px;
    height: 56px;
  }

  .adv-modal__close-btn {
    top: 30px;
    right: 26px;
  }

  .input-wrapper {
    width: 610px;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .input-wrapper:not(:last-child) {
    margin-bottom: 16px;
  }

  .input-wrapper:last-child {
    margin-bottom: 34px;
  }

  .adv-modal__product-name::-ms-input-placeholder, .adv-modal__product-description::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0);
  }

  .adv-modal__product-name::placeholder, .adv-modal__product-description::placeholder {
    color: rgba(0, 0, 0, 0);
  }

  .adv-modal__product-photos {
    margin: 0;
  }

  .adv-modal__product-select {
    background-position: 95%;
  }

  .add-product-btn {
    width: 294px;
    height: 60px;
    margin-right: 12px;
    font-size: 14px;
  }

  .select-option-hiden {
    font-size: 0;
  }
}

@media (min-width: 1280px) {
  .adv-modal {
    width: 950px;
    padding-top: 54px;
    padding-bottom: 56px;
  }

  .input-wrapper {
    width: 828px;
  }

  .input {
    width: 600px;
    height: 56px;
  }

  .adv-modal__product-description {
    min-height: 56px;
  }

  .adv-modal__product-photos {
    width: 600px;
  }

  .input-label, .input-label__img {
    width: 90px;
    height: 60px;
  }

  .add-product-btn {
    width: 294px;
    height: 60px;
    margin-right: 76px;
  }
}

@media (max-width: 767px) {
  .loader-wrapper {
    height: 100vh;
  }

  .block__list {
    max-height: 366px;
    background-image: url("background-color-blue.a51ab7d7.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .block-item {
    padding: 110px 0 100px;
  }

  .item-main-info {
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }

  .item-info {
    width: 150px;
    height: 100px;
    text-align: center;
  }

  .item-img {
    max-width: 150px;
    max-height: 160px;
  }

  .item-name {
    color: #fff;
    text-align: center;
    font-family: MontserratSemiBold;
    font-size: 17px;
    line-height: 1.2;
    display: block;
  }

  .item-price {
    color: #ff6b08;
    text-transform: uppercase;
    font-family: MontserratSemiBold;
    font-size: 33px;
    line-height: 1.2;
    display: block;
  }

  .item-currency {
    color: #ff6b08;
    text-transform: uppercase;
    font-family: MontserratSemiBold;
    font-size: 8px;
    line-height: 1.2;
  }

  .buy {
    color: #fff;
    text-align: center;
    width: 129px;
    height: 44px;
    background-color: #ff6b08;
    outline: none;
    margin: 0 auto;
    font-family: MontserratSemiBold;
    font-size: 14px;
    line-height: 1.2;
    display: block;
  }
}

@media (min-width: 768px) {
  .loader-wrapper {
    height: 100vh;
  }

  .block__list {
    max-height: 370px;
    background-image: url("background-color-blue-md.8b7a3a1c.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .block-item {
    padding: 57px 0;
  }

  .item-main-info {
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }

  .item-info {
    width: 334px;
    height: 120px;
    text-align: center;
  }

  .item-img {
    max-width: 241px;
    max-height: 256px;
  }

  .item-name {
    color: #fff;
    text-align: right;
    font-family: MontserratSemiBold;
    font-size: 40px;
    line-height: 1.2;
    display: block;
  }

  .item-price {
    text-align: right;
    color: #ff6b08;
    text-transform: uppercase;
    font-family: MontserratSemiBold;
    font-size: 55px;
    line-height: 1.2;
    display: block;
  }

  .item-currency {
    color: #ff6b08;
    text-transform: uppercase;
    text-align: right;
    font-family: MontserratSemiBold;
    font-size: 15px;
    line-height: 1.2;
  }

  .buy {
    color: #fff;
    text-align: center;
    width: 129px;
    height: 44px;
    background-color: #ff6b08;
    outline: none;
    margin-left: auto;
    font-family: MontserratSemiBold;
    font-size: 14px;
    line-height: 1.2;
    display: block;
  }
}

@media (min-width: 1280px) {
  .loader-wrapper {
    height: 100vh;
  }

  .block__list {
    width: 66.6%;
    float: left;
    max-height: 480px;
  }

  .block-item {
    padding: 112px 0;
  }

  .horizontal-block {
    display: flex;
  }
}

.block__list {
  opacity: 0;
}

.block__list-show {
  opacity: 1;
  transition: opacity .5s linear;
}

.arround-block__list {
  opacity: 0;
}

.arround-block__list-show {
  opacity: 1;
  transition: opacity .5s linear;
}

.horizontal-block {
  opacity: 0;
}

.horizontal-block-show {
  opacity: 1;
  transition: opacity .5s linear;
}

@media (min-width: 768px) and (max-width: 1279px) {
  .arround-block__list {
    justify-content: space-evenly;
    display: flex;
  }

  .arround__item:first-of-type {
    background-color: #dcdde0;
  }

  .arround__item:nth-child(2) {
    background-color: #fafafa;
  }

  .arround__item {
    width: 50%;
    height: 220px;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }

  .arround-img {
    max-width: 170px;
    max-height: 200px;
  }

  .arround-name {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
    display: block;
  }

  .arround-price {
    color: #000;
    text-transform: uppercase;
    text-align: left;
    font-family: Montserrat;
    font-size: 42px;
    font-weight: bold;
    line-height: 1.2;
  }

  .arround__item:nth-last-of-type(1) .arround-price {
    color: #ff6b08;
  }

  .arround-currency {
    color: #000;
    text-transform: uppercase;
    text-align: right;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: bold;
    line-height: 1.2;
  }

  .arround__item:nth-last-of-type(1) .arround-currency {
    color: #ff6b08;
  }
}

@media (min-width: 1280px) {
  .arround-block__list {
    height: 480px;
  }

  .arround__item:first-of-type {
    background-color: #dcdde0;
  }

  .arround__item:nth-child(2) {
    background-color: #fafafa;
  }

  .arround__item:nth-child(3) {
    background-color: #ff6b08;
  }

  .arround__item {
    width: 33.4%;
    height: 240px;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }

  .arround-img {
    max-width: 170px;
    max-height: 210px;
  }

  .arround-name {
    color: #000;
    text-align: center;
    font-family: MontserratSemiBold;
    font-size: 18px;
    line-height: 1.2;
    display: block;
  }

  .arround-price {
    color: #000;
    text-transform: uppercase;
    text-align: left;
    font-family: MontserratSemiBold;
    font-size: 42px;
    line-height: 1.2;
  }

  .arround__item:nth-last-of-type(2) .arround-price {
    color: #ff6b08;
  }

  .arround__item:nth-last-of-type(1) .arround-price {
    color: #fff;
  }

  .arround-currency {
    color: #000;
    text-transform: uppercase;
    text-align: right;
    font-family: MontserratSemiBold;
    font-size: 11px;
    line-height: 1.2;
  }

  .arround__item:nth-last-of-type(2) .arround-currency {
    color: #ff6b08;
  }

  .arround__item:nth-last-of-type(1) .arround-currency {
    color: #fff;
  }

  .arround-block__list .arround__item:nth-of-type(1) .arround-info .arround-price, .arround-block__list .arround__item:nth-of-type(1) .arround-info .arround-currency {
    color: #000;
  }

  .arround-block__list .arround__item:nth-of-type(2) .arround-info .arround-price, .arround-block__list .arround__item:nth-of-type(2) .arround-info .arround-currency {
    color: #ff6b08;
  }
}

.load-more {
  color: #fff;
  width: 100%;
  background-color: #121d2e;
  border: none;
  outline: none;
  font-family: MontserratSemiBold;
  line-height: 1.2;
}

.load-more:hover {
  color: #ff6b08;
}

@media (max-width: 767px) {
  .load-more {
    height: 70px;
    font-size: 18px;
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  .load-more {
    height: 88px;
    font-size: 20px;
  }
}

@media (min-width: 1280px) {
  .load-more {
    height: 108px;
    cursor: pointer;
    font-size: 20px;
    transition: all .2s linear;
  }
}

.text-1 {
  margin-bottom: 22px;
}

.footer {
  color: #767e85;
  text-align: center;
  background-color: #1e2939;
  padding: 42px 0;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.line-1:after {
  content: "";
  width: 1px;
  height: 12px;
  background-image: url("vertical-line.f0bdebb4.png");
  position: absolute;
}

@media (min-width: 768px) {
  .line-2:after {
    content: "";
    width: 1px;
    height: 12px;
    background-image: url("vertical-line.f0bdebb4.png");
    position: absolute;
  }

  .text-1, .text-2 {
    display: inline-block;
  }

  .text-1 {
    margin-bottom: 0;
  }

  .footer {
    padding: 60px 0;
  }
}

.student-modal {
  width: 280px;
  background: #fff;
  padding: 10px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 8px 24px rgba(29, 29, 27, .1);
}

.student-modal__close-btn {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("close-24px.0e7c4041.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  position: absolute;
  top: 22px;
  right: 16px;
}

.student-modal__form--title {
  color: #333;
  margin-bottom: 10px;
  margin-left: 18px;
  font-family: MontserratBold;
  font-size: 18px;
  line-height: 1.2;
}

.student-modal__form--title:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.student-modal__form--title:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.student-modal__form--title:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.student-modal__list {
  width: 270px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

.student-modal__item {
  width: 135px;
  text-align: center;
  font-size: 11px;
}

.student-modal__item:first-child {
  width: 270px;
}

.student-modal__item:not(:last-child) {
  margin-bottom: 10px;
}

.student-modal__avatar {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 2px solid #ff6b08;
  border-radius: 50%;
  margin: 0 auto;
}

.student-modal__name, .student-modal__email {
  font-family: MontserratLight, sans-serif;
}

.footer__our-students {
  color: #ff6b08;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
}

.footer__our-students:hover, .footer__our-students:focus {
  color: #fff;
}

@media (min-width: 768px) {
  .student-modal {
    width: 600px;
    padding: 48px 0;
    box-shadow: 0 12px 40px rgba(0, 0, 0, .25);
  }

  .student-modal__form--title {
    margin-bottom: 20px;
    margin-left: 54px;
    font-size: 22px;
  }

  .student-modal__list {
    width: 570px;
  }

  .student-modal__item {
    width: 190px;
    text-align: center;
    font-size: 14px;
  }

  .student-modal__item:first-child {
    width: 190px;
  }

  .student-modal__item:not(:last-child) {
    margin-bottom: 0;
  }

  .student-modal__item:not(:nth-last-child(-n+3)) {
    margin-bottom: 20px;
  }

  .student-modal__avatar {
    width: 80px;
    height: 80px;
  }
}

@media (min-width: 1280px) {
  .student-modal {
    width: 1000px;
    padding: 54px 0;
  }

  .student-modal__list {
    width: 900px;
  }

  .student-modal__item {
    width: 300px;
    text-align: center;
    font-size: 16px;
  }

  .student-modal__item:first-child {
    width: 300px;
  }

  .student-modal__item:not(:last-child) {
    margin-bottom: 0;
  }

  .student-modal__item:not(:nth-last-child(-n+3)) {
    margin-bottom: 30px;
  }

  .student-modal__avatar {
    width: 100px;
    height: 100px;
    margin-bottom: 5px;
  }

  .student-modal__name {
    margin-bottom: 5px;
  }
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  animation-duration: .5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  animation-duration: .8s;
  -webkit-animation-duration: calc(var(--animate-duration) * .8);
  animation-duration: calc(var(--animate-duration) * .8);
}

.animate__animated.animate__slow {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animate__animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes bounce {
  from, 20%, 53%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.animate__bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.animate__flash {
  animation-name: flash;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__rubberBand {
  animation-name: rubberBand;
}

@keyframes shakeX {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  animation-name: shakeX;
}

@keyframes shakeY {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  animation-name: shakeY;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.animate__headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.animate__swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.animate__jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.animate__heartBeat {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}

@keyframes backInDown {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInDown {
  animation-name: backInDown;
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInLeft {
  animation-name: backInLeft;
}

@keyframes backInRight {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInRight {
  animation-name: backInRight;
}

@keyframes backInUp {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInUp {
  animation-name: backInUp;
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(700px)scale(.7);
  }
}

.animate__backOutDown {
  animation-name: backOutDown;
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

.animate__backOutLeft {
  animation-name: backOutLeft;
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

.animate__backOutRight {
  animation-name: backOutRight;
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(-700px)scale(.7);
  }
}

.animate__backOutUp {
  animation-name: backOutUp;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.animate__bounceIn {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.animate__bounceOut {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.animate__bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.animate__bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopLeft {
  animation-name: fadeInTopLeft;
}

@keyframes fadeInTopRight {
  from {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopRight {
  animation-name: fadeInTopRight;
}

@keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomLeft {
  animation-name: fadeInBottomLeft;
}

@keyframes fadeInBottomRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomRight {
  animation-name: fadeInBottomRight;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate__fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.animate__fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.animate__fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.animate__fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.animate__fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }
}

.animate__fadeOutTopLeft {
  animation-name: fadeOutTopLeft;
}

@keyframes fadeOutTopRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }
}

.animate__fadeOutTopRight {
  animation-name: fadeOutTopRight;
}

@keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }
}

.animate__fadeOutBottomRight {
  animation-name: fadeOutBottomRight;
}

@keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }
}

.animate__fadeOutBottomLeft {
  animation-name: fadeOutBottomLeft;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0deg);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0deg);
  }
}

.animate__animated.animate__flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInX {
  animation-name: flipInX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInY {
  animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.animate__flipOutX {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.animate__flipOutY {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes lightSpeedInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInRight {
  animation-name: lightSpeedInRight;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInLeft {
  animation-name: lightSpeedInLeft;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.animate__lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}

@keyframes lightSpeedOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.animate__lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateIn {
  transform-origin: center;
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.animate__rotateOut {
  transform-origin: center;
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.animate__rotateOutDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.animate__rotateOutUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.animate__hinge {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  transform-origin: 0 0;
  animation-name: hinge;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.animate__rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.animate__zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.animate__zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.animate__zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.animate__zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.animate__zoomOutDown {
  transform-origin: bottom;
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.animate__zoomOutLeft {
  transform-origin: 0;
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.animate__zoomOutRight {
  transform-origin: 100%;
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.animate__zoomOutUp {
  transform-origin: bottom;
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__slideOutUp {
  animation-name: slideOutUp;
}

/*# sourceMappingURL=index.9814228b.css.map */

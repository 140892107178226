.categories {
  position: relative;
  min-height: 380px;
  /* padding-top: 54px; */
}

.category-list {
  padding-bottom: 54px;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.category-list:first-of-type {
  padding-top: 54px;
}

.category-info {
  margin-bottom: 20px;
}

.category-name {
  font-size: 20px;
  font-family: 'MontserratSemiBold';
  color: rgb(0, 0, 0);
  line-height: 1.2;
  text-align: left;
  margin-right: 12px;
}

.view-all {
  cursor: pointer;
  font-size: 12px;
  font-family: 'MontserratRegular';
  color: rgb(255, 107, 8);
  text-decoration: underline;
  line-height: 1.2;
  text-align: left;
}

.category-description {
  font-size: 12px;
  font-family: 'MontserratRegular';
  color: rgb(168, 168, 168);
  line-height: 1.5;
  text-align: left;
  margin-bottom: 26px;
}

.things-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  /* width: 280px; */
  width: 100%;
  height: 305px;
  padding: 20px 0px;
  cursor: pointer;
  transition: transform 0.5s, filter 1.5s ease-in-out;
}

.things-item:hover {
  transform: scale(1.1);
}

.thing-img {
  object-fit: cover;
  width: 240px;
  height: 240px;
  margin: 0 auto 5px;
}

.thing-name {
  display: block;
  font-size: 12px;
  font-family: 'MontserratSemiBold';
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center;
  max-width: 240px;
  max-height: 14px;
  margin: 0 auto;
  overflow: hidden;
}

.thing-price {
  display: block;
  font-size: 12px;
  font-family: 'MontserratSemiBold';
  color: rgb(233, 97, 5);
  line-height: 1.2;
  text-align: center;
}

.hide {
  display: none;
}

.hide-two {
  opacity: 0;
}

.all-category {
  opacity: 0;
  min-height: 500px;
  /* transition: all 1s linear; */
}

.all-category-show {
  transition: all 1s linear;
  opacity: 1;
}

.close-category {
  opacity: 0;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  background-color: #ff6b08;
  box-shadow: 0px 8px 8px 0px rgba(233, 97, 5, 0.24);
  outline: none;
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 1;
  /* transition: all 500ms linear; */
  background-image: url(../images/baseline_close_black_24dp.png);
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.close-category-show {
  transition: opacity 1s linear, background-color 500ms;
  opacity: 1;
}

.close-category:hover {
  /* transition: all 200ms linear; */
  background-color: rgb(255, 255, 255);
}
.things-item {
  min-width: 280px !important;
}

@media (max-width: 767px) {
  .category-wrapper {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    width: 100%;
  }
  .electronics-wrapper {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    width: 100%;
  }
  .property-wrapper {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    width: 100%;
  }
  .transport-wrapper {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    width: 100%;
  }
  .businessAndServices-wrapper {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    width: 100%;
  }
  .recreationAndSport-wrapper {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    width: 100%;
  }
  .free-wrapper {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    width: 100%;
  }
  .exchange-wrapper {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    width: 100%;
  }

  .category-list {
    width: 100%;
  }

  .categories {
    position: relative;
  }

  .close-category {
    top: 30px;
    right: 22px;
    background-color: #ffffff;
  }

  .slide-prev,
  .slide-next {
    padding: 5px;
    width: 10px;
    height: 20px;
    border: none;
    outline: none;
    margin-top: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgb(244, 244, 244);
    box-shadow: 2.5px 4.33px 10px 0px rgba(29, 29, 27, 0.11);
  }
  .slide-prev:hover,
  .slide-next:hover {
    background-color: rgb(233, 97, 5);
  }
  .slide-prev {
    background-image: url(../images/back.svg);
    left: 0;
    margin-right: 5px;
  }
  .slide-next {
    right: 0;
    background-image: url(../images/next.svg);
  }

  .modal-btn {
    display: block;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    background-color: #ff6b08;
    box-shadow: 0px 8px 8px 0px rgba(233, 97, 5, 0.24);
    position: fixed;
    right: 22px;
    top: 80vh;
    outline: none;
  }

  .modal-btn::after {
    content: '+';
    color: #ffffff;
  }

  .things-list {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
}

@media (min-width: 768px) {
  .category-list {
    position: relative;
  }

  main .modal-btn {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
  }

  .slide-prev {
    position: absolute;
    top: 50%;
    background-color: rgb(244, 244, 244);
    box-shadow: 2.5px 4.33px 10px 0px rgba(29, 29, 27, 0.11);
    width: 32px;
    height: 60px;
    border: none;
    outline: none;
    background-image: url(../images/back.svg);
    background-repeat: no-repeat;
    background-position: center;
    transition: all 200ms linear;
  }

  .slide-prev:hover {
    background-color: rgb(233, 97, 5);
  }

  .slide-next {
    position: absolute;
    top: 50%;
    right: 0;
    background-color: rgb(244, 244, 244);
    box-shadow: 2.5px 4.33px 10px 0px rgba(29, 29, 27, 0.11);
    width: 32px;
    height: 60px;
    border: none;
    outline: none;
    background-image: url(../images/next.svg);
    background-repeat: no-repeat;
    background-position: center;
    transition: all 200ms linear;
  }

  .slide-next:hover {
    background-color: rgb(233, 97, 5);
  }

  .things-list {
    padding: 2px 0px;
  }

  .things-item {
    display: block;
    margin: 0 auto 20px;
    /* width: 333px; */
    height: 305px;

    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  }

  .all-category {
    background-color: rgb(255, 255, 255);
    z-index: 1;
  }

  .category-line {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 1200px) {
  .close-category {
    right: 120px;
  }

  .all-category {
    min-height: 700px;
  }
}

.not-found {
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #000;
  padding-top: 200px;
  text-align: center;
}

/* ===========mobile============ */
@media (max-width: 767px) {
  .loader-wrapper {
    height: 100vh;
  }
  .block__list {
    /* min-height: 370px; */
    background-image: url('../images/background-color-blue.png');
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 366px;
  }
  .block-item {
    padding: 110px 0 100px 0;
  }
  .item-main-info {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
  }
  .item-info {
    width: 150px;
    height: 100px;
    text-align: center;
  }
  .item-img {
    max-width: 150px;
    max-height: 160px;
  }
  .item-name {
    display: block;
    font-size: 17px;
    font-family: 'MontserratSemiBold';
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
  }
  .item-price {
    display: block;
    font-size: 33px;
    font-family: 'MontserratSemiBold';
    color: rgb(255, 107, 8);
    text-transform: uppercase;
    line-height: 1.2;
  }
  .item-currency {
    font-size: 8px;
    font-family: 'MontserratSemiBold';
    color: rgb(255, 107, 8);
    text-transform: uppercase;
    line-height: 1.2;
  }
  .buy {
    display: block;
    margin: 0 auto;
    font-size: 14px;
    font-family: 'MontserratSemiBold';
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
    background-color: rgb(255, 107, 8);
    width: 129px;
    height: 44px;
    outline: none;
  }
}
/* ===================tablet=================== */
@media (min-width: 768px) {
  .loader-wrapper {
    height: 100vh;
  }
  .block__list {
    /* min-height: 370px; */
    background-image: url('../images/background-color-blue-md.png');
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 370px;
  }
  .block-item {
    padding: 57px 0 57px 0;
  }
  .item-main-info {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
  }
  .item-info {
    width: 334px;
    height: 120px;
    text-align: center;
  }
  .item-img {
    max-width: 241px;
    max-height: 256px;
  }
  .item-name {
    display: block;
    font-size: 40px;
    font-family: 'MontserratSemiBold';
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: right;
  }
  .item-price {
    display: block;
    text-align: right;
    font-size: 55px;
    font-family: 'MontserratSemiBold';
    color: rgb(255, 107, 8);
    text-transform: uppercase;
    line-height: 1.2;
  }

  .item-currency {
    font-size: 15px;
    font-family: 'MontserratSemiBold';
    color: rgb(255, 107, 8);
    text-transform: uppercase;
    line-height: 1.2;
    text-align: right;
  }

  .buy {
    display: block;
    margin-left: auto;
    font-size: 14px;
    font-family: 'MontserratSemiBold';
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
    background-color: rgb(255, 107, 8);
    width: 129px;
    height: 44px;
    outline: none;
  }
}
/* ==================desktop================== */
@media (min-width: 1280px) {
  .loader-wrapper {
    height: 100vh;
  }
  .block__list {
    width: 66.6%;
    float: left;
    max-height: 480px;
  }
  .block-item {
    padding: 112px 0;
  }
  .horizontal-block {
    display: flex;
  }
}
/* ===================loader=================== */
.block__list {
  opacity: 0;
}
.block__list-show {
  transition: opacity 500ms linear;
  opacity: 1;
}
.arround-block__list {
  opacity: 0;
}
.arround-block__list-show {
  transition: opacity 500ms linear;
  opacity: 1;
}
.horizontal-block {
  opacity: 0;
}
.horizontal-block-show {
  transition: opacity 500ms linear;
  opacity: 1;
}

.auth-modal-err {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 8px 24px 0px rgba(29, 29, 27, 0.1);
  padding: 25px 0;
}

.auth-modal-err__close-btn {
  position: absolute;
  top: 22px;
  right: 16px;
  background-color: transparent;
  width: 14px;
  height: 14px;
  background-image: url('../../../assets/icons/close/close-24px.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.auth-modal-err__form--title {
  font-family: 'MontserratBold';
  font-size: 18px;
  color: rgb(51, 51, 51);
  line-height: 1.2;
  text-align: start;
  margin-bottom: 20px;
  margin-left: 18px;
}

.auth-modal-err__text {
  padding: 15px 0 0;
  width: 246px;
  margin: 0 auto 20px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  color: rgb(17, 36, 55);
}

@media (min-width: 768px) {
  .auth-modal-err {
    width: 400px;
    box-shadow: 0px 12px 40px 0px rgba(0, 0, 0, 0.25);
    padding-top: 48px 0;
  }

  .auth-modal-err__form--title {
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 20px;
    margin-left: 54px;
  }
}

@media (min-width: 1280px) {
  .auth-modal-err {
    padding: 54px 0;
  }
}

.load-more {
  background-color: #121d2e;
  font-family: 'MontserratSemiBold';
  color: rgb(255, 255, 255);
  line-height: 1.2;
  width: 100%;
  border: none;
  outline: none;
}

.load-more:hover {
  color: #ff6b08;
}

@media (max-width: 767px) {
  .load-more {
    height: 70px;
    font-size: 18px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .load-more {
    font-size: 20px;
    height: 88px;
  }
}
@media (min-width: 1280px) {
  .load-more {
    height: 108px;
    font-size: 20px;
    cursor: pointer;
    transition: all 200ms linear;
  }
}

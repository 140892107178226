.student-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 8px 24px 0px rgba(29, 29, 27, 0.1);
  padding: 10px 0;
}

.student-modal__close-btn {
  position: absolute;
  top: 22px;
  right: 16px;
  background-color: transparent;
  width: 14px;
  height: 14px;
  background-image: url('../../../assets/icons/close/close-24px.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.student-modal__form--title {
  font-family: 'MontserratBold';
  font-size: 18px;
  color: rgb(51, 51, 51);
  line-height: 1.2;
  text-align: start;
  margin-bottom: 10px;
  margin-left: 18px;
}

.student-modal__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 270px;
  margin: 0 auto;
}

.student-modal__item {
  width: 135px;
  text-align: center;
  font-size: 11px;
}

.student-modal__item:first-child {
  width: 270px;
}

.student-modal__item:not(:last-child) {
  margin-bottom: 10px;
}

.student-modal__avatar {
  border: 2px solid #ff6b08;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  object-fit: cover;
}

.student-modal__name {
  font-family: 'MontserratLight', sans-serif;
}

.student-modal__email {
  font-family: 'MontserratLight', sans-serif;
}

.footer__our-students {
  background-color: transparent;
  border: none;
  outline: none;
  color: #ff6b08;
  cursor: pointer;
}

.footer__our-students:hover,
.footer__our-students:focus {
  color: #fff;
}

@media (min-width: 768px) {
  .student-modal {
    width: 600px;
    box-shadow: 0px 12px 40px 0px rgba(0, 0, 0, 0.25);
    padding: 48px 0;
  }

  .student-modal__form--title {
    font-size: 22px;
    margin-bottom: 20px;
    margin-left: 54px;
  }

  .student-modal__list {
    width: 570px;
  }

  .student-modal__item {
    width: 190px;
    text-align: center;
    font-size: 14px;
  }

  .student-modal__item:first-child {
    width: 190px;
  }

  .student-modal__item:not(:last-child) {
    margin-bottom: 0;
  }

  .student-modal__item:not(:nth-last-child(-n + 3)) {
    margin-bottom: 20px;
  }

  .student-modal__avatar {
    width: 80px;
    height: 80px;
  }
}

@media (min-width: 1280px) {
  .student-modal {
    width: 1000px;
    padding: 54px 0;
  }

  .student-modal__list {
    width: 900px;
  }

  .student-modal__item {
    width: 300px;
    text-align: center;
    font-size: 16px;
  }

  .student-modal__item:first-child {
    width: 300px;
  }

  .student-modal__item:not(:last-child) {
    margin-bottom: 0;
  }

  .student-modal__item:not(:nth-last-child(-n + 3)) {
    margin-bottom: 30px;
  }

  .student-modal__avatar {
    width: 100px;
    height: 100px;
    margin-bottom: 5px;
  }

  .student-modal__name {
    margin-bottom: 5px;
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.header {
  background-color: #112437;
  padding: 21px 0px;
}

.header__logo {
  display: block;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: url(../images/new-logo.svg);
  /* color: #0242a1 */
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
}
.header__chat-link {
  position: absolute;
  font-family: 'MontserratMedium', sans-serif;
  line-height: 26px;
  font-size: 12px;
  color: #fff;
  transform: translate(500px, 0);
  cursor: pointer;
  z-index: 1;
  transition: color 0.5s;
}
.header__chat-link:hover {
  color: #ff6b08;
}

.mobile-menu-closed .header__chat-link {
  transform: translate(34vw, 90vh);
  color: #818181;
}

.mobile-menu-closed {
  position: absolute;
  left: -93.75vw;
  top: 0;
  display: flex;
  width: 93.75vw;
  height: 100vh;
  background-color: #031c33;
  transition: 0.5s;
  z-index: 3;
}

.mobile-menu-opened {
  box-shadow: 15px 0px 25px 0px rgba(29, 29, 27, 0.15);
  transform: translateX(93.75vw);
  transition: 0.5s;
  z-index: 3;
}

.header-auth-mobile {
  position: relative;
  display: flex;
  align-items: center;
  transform: translateX(-50%);
  left: 46.875vw;
  top: 14.4vh;
  height: 30px;
}

.mobile-filters-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  margin-right: 26px;
  cursor: pointer;
  height: 24px;
}

.mobile-clear-filters-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
  height: 24px;
}

.header__filters--btn {
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: url(../../../assets/icons/filters/tune-24px.svg);
  outline: none;
  margin-right: 5px;
  transition: 0.5s;
}

.header__filters {
  font-family: 'MontserratMedium', sans-serif;
  line-height: 26px;
  font-size: 10px;
  color: #818181;
  transition: 0.5s;
}

.header__clear-filter--btn {
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: url(../../../assets/icons/restore/replay-24px.svg);
  margin-right: 4px;
  outline: none;
  transition: 0.5s;
}

.header__clear-filter {
  font-family: 'MontserratMedium', sans-serif;
  line-height: 24px;
  font-size: 10px;
  color: #818181;
  transition: 0.5s;
}

.mobile-buttons-wrapper {
  display: flex;
  position: absolute;
  height: 24px;
  box-sizing: border-box;
  left: 6.7vw;
  top: 27.8vh;
}

.mobile-buttons-wrapper .header__clear-filter {
  line-height: 24px;
}

.mobile-buttons-wrapper .header__filters {
  line-height: 24px;
}

.categories-filter-mobile {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 280px;
  height: 38.7vh;
  left: 6.7vw;
  top: 36vh;
}

.categories-filter__item:not(:last-of-type) {
  margin-right: 8px;
}

.categories-filter__item--btn {
  padding: 8px 10px;
  font-family: 'MontserratLight', sans-serif;
  font-size: 19px;
  color: #818181;
  background-color: transparent;
  border-width: 1px;
  border-radius: 50px;
  border-color: rgb(206, 210, 209);
  border-style: solid;
  opacity: 0.5;
  outline: none;
  transition: 0.5s;
}

.active-category {
  color: #ff6b08;
  border-color: #ff6b08;
  opacity: 1;
}

.close-menu {
  background: url(../../../assets/icons/close/close-24px.svg);
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-24px, 24px);
}

.mobile-input-closed {
  display: none;
  position: absolute;
  font-family: 'MontserratLight', sans-serif;
  outline: none;
  color: black;
  font-size: 12px;
  width: 300px;
  height: 40px;
  padding-left: 30px;
  margin-right: 81px;
  opacity: 0;
  transition: 0.5s;
}

.mobile-input {
  display: unset;
  opacity: 1;
  transform: translateY(11px);
}

.mobile-input-btn {
  display: none;
  position: absolute;
  cursor: pointer;
  transform: translate(128px, 11px);
  border: none;
  outline: none;
  z-index: 1;
  width: 24px;
  height: 24px;
  background: url(../../../assets/icons/search/search-24px-black.svg);
}

.mobile-input-cross {
  display: none;
  position: absolute;
  z-index: 1;
  background: url(../../../assets/icons/close/close-24px.svg);
  cursor: pointer;
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
  transform: translate(-133px, 11px);
}

.mobile-clear-filters-wrapper:hover .header__clear-filter {
  color: #ff6b08;
}

.mobile-clear-filters-wrapper:hover .header__clear-filter--btn {
  background: url(../../../assets/icons/restore/restore-24px-orange.svg);
}

.mobile-filters-wrapper:hover .header__filters {
  color: #ff6b08;
}

.mobile-filters-wrapper:hover .header__filters--btn {
  background: url(../../../assets/icons/filters/tune-24px-orange.svg);
}

.account-menu__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: #112437;
  box-shadow: 15px 0px 25px 0px rgba(29, 29, 27, 0.15);
}
.account-btn {
  position: relative;
  width: 180px;
  height: max-content;
  border: none;
  background-color: transparent;
  outline: none;
}
.account-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-30%, -50%);
  font-family: 'MontserratRegular', sans-serif;
  font-size: 12px;
  color: #ffffff;
  transition: 0.4s;
  height: max-content;
}
.account-avatar {
  width: 44px;
  height: 44px;
  margin-right: 20px;
}
.avatar {
  width: 100%;
  border-radius: 50%;
  border: 2px solid #ffffff;
  transition: 0.4s;
}
.account-btn:hover {
  cursor: pointer;
}
.account-btn:hover .account-title,
.account-btn:hover .avatar--hover {
  color: #ff6b08;
  transition: 0.4s;
}
.account-btn:hover .avatar--hover {
  border: 2px solid #ff6b08;
}

@media (max-width: 767px) {
  .header {
    min-height: 92px;
  }

  /* ++ */

  .account-menu__wrapper {
    background-color: #031c33;
    margin-bottom: 30px;
  }

  .tablet-upper-wrapper {
    display: none;
  }

  .tablet-bottom-wrapper {
    display: none;
  }

  .mobile-burger {
    border: none;
    outline: none;
    width: 24px;
    height: 24px;
    background: url(../../../assets/icons/menu/dehaze-24px.svg);
  }

  .mobile-search {
    border: none;
    outline: none;
    width: 24px;
    height: 24px;
    background: url(../../../assets/icons/search/search-24px.svg);
  }

  .mobile-menu-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .header {
    padding: 34px 0;
  }

  .mobile-menu-wrapper {
    display: none;
  }

  .tablet-upper-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .header__logo {
    margin: 0 266px 0 0;
  }

  .header-auth {
    display: flex;
    width: 140px;
    align-items: center;
    justify-content: space-around;
    height: 26px;
    margin-right: 30px;
  }

  .header__create-ad-btn {
    display: block;
    font-family: 'MontserratBold', sans-serif;
    font-size: 14px;
    letter-spacing: 0.28px;
    cursor: pointer;
    width: 200px;
    height: 44px;
    background-color: #ff6b08;
    color: #fff;
    border: none;
    outline: none;
    transition: box-shadow 0.5s linear;
  }

  .header__create-ad-btn:hover {
    box-shadow: 0px 2px 20px 10px rgba(255, 107, 8, 0.4);
  }

  .tablet-bottom-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  .header__input {
    font-family: 'MontserratLight', sans-serif;
    outline: none;
    color: black;
    font-size: 12px;
    width: 405px;
    height: 44px;
    padding-left: 10px;
    margin-right: 81px;
  }

  .header__input--btn {
    position: absolute;
    cursor: pointer;
    transform: translate(375px, 0px);
    border: none;
    outline: none;
    width: 24px;
    height: 24px;
    background: url(../../../assets/icons/search/search-24px-black.svg);
  }

  .clear-filters-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    cursor: pointer;
  }

  .filters-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    margin-right: 48px;
    cursor: pointer;
  }

  .clear-filters-wrapper:hover .header__clear-filter {
    color: #ff6b08;
  }

  .clear-filters-wrapper:hover .header__clear-filter--btn {
    background: url(../../../assets/icons/restore/restore-24px-orange.svg);
  }

  .filters-wrapper:hover .header__filters {
    color: #ff6b08;
  }

  .filters-wrapper:hover .header__filters--btn {
    background: url(../../../assets/icons/filters/tune-24px-orange.svg);
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  .header__chat-link {
    transform: translate(75px, 0px);
  }

  .categories-filter-tablet {
    display: none;
    height: 100px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 15px;
  }

  .desktop-upper-wrapper .header__input {
    display: none;
  }

  .tablet-bottom-wrapper .categories-filter {
    display: none;
  }

  .tablet-upper-wrapper .header__input--btn {
    display: none;
  }
}

@media (min-width: 1280px) {
  .tablet-bottom-wrapper .header__input {
    display: none;
  }

  .categories-filter-tablet {
    display: none;
  }

  .desktop-upper-wrapper .header__input {
    outline: none;
    width: 405px;
    height: 44px;
    margin-right: 286px;
  }

  .tablet-bottom-wrapper .header__input--btn {
    display: none;
  }

  .tablet-bottom-wrapper .filters-wrapper {
    display: none;
  }

  .clear-filters-wrapper {
    margin: 0;
    position: absolute;
    right: 0;
  }

  .header__logo {
    margin-right: 35px;
  }

  .header__create-ad-btn {
    width: 233px;
  }

  .header-auth {
    margin-right: 55px;
  }

  .desktop-upper-wrapper .header__logo {
    margin-right: 36px;
  }

  .desktop-upper-wrapper {
    margin-bottom: 24px;
  }

  .header__clear-filter {
    font-size: 12px;
  }

  .categories-filter {
    display: flex;
  }

  .categories-filter__item:not(:last-of-type) {
    margin-right: 12px;
    cursor: pointer;
  }

  .categories-filter__item:hover .categories-filter__item--btn {
    cursor: pointer;
    border-color: #ff6b08;
    color: #ff6b08;
    opacity: 1;
  }

  .header__input--btn {
    transform: translate(456px, 0px);
  }
}
